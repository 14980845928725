<template>
  <div :class="$style.HomeCourse">
    <h2 class="h2">{{ $t('reserves') }}</h2>
    <div :class="$style.search">
      {{ $t('search by name') }}
      <VInput
        v-model="search"
        :placeholder="$t('enter the name')"
        size="small"
        :class="$style.input"
        :prepend-inner-icon="IconsSearch"
        @input="page = 1"
      />
    </div>
    <PagesHomeCourseTable :items="result" :loading="pending" :empty="empty" />
    <transition name="fade" mode="out-in">
      <div v-if="searchResult.length > 5" :class="$style.bottom">
        <div :class="$style.text">
          Showing
          <span
            >{{ (page - 1) * itemsPerPage + 1 }}-{{
              (page - 1) * itemsPerPage + result.length
            }}</span
          >
          from <span>{{ searchResult.length }}</span> data
        </div>
        <CommonThePagination
          :current-page="page"
          :per-page="5"
          :total-pages="Math.ceil(searchResult.length / itemsPerPage)"
          @page-changed="page = $event"
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import VInput from '~/components/ui/Input/VInput.vue';
import IconsSearch from '~/components/Icons/IconsSearch.vue';
import type { Direction } from '~/types/calculator';
import { calculatorApi } from '~/utils/api/calculator';

const search = ref('');

interface Props {
  directions: Direction[];
}

const props = defineProps<Props>();

const { data: reserves, pending } = await calculatorApi.getReserves();

const items = computed(() => {
  return props.directions.map((item) => {
    return {
      name: item.name,
      logo: item.logo.simple,
      reserve: reserves?.value?.find((reserve) => item.ids[0] === reserve.id)
        ?.reserve,
      currency: item.currency[0],
    };
  });
});

const searchResult = computed(() => {
  return items.value.filter((item) =>
    item.name.toLowerCase().includes(search.value.toLowerCase())
  );
});

const page = ref(1);
const itemsPerPage = ref(5);

const result = computed(() => {
  return searchResult.value.slice(
    (page.value - 1) * itemsPerPage.value,
    page.value * itemsPerPage.value
  );
});

const empty = computed(() => {
  return searchResult.value.length === 0;
});
</script>

<style lang="scss" module>
.HomeCourse {
  position: relative;
  margin-top: 7.2rem;

  @include respond-to(xs) {
    display: none;
  }
}

.search {
  display: flex;
  align-items: center;
  margin-top: 2.7rem;
  margin-left: 5.8rem;
}

.input {
  width: 20rem;
  margin-left: 1.2rem;
}

.icon {
  color: #1d2d36;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3.1rem;
}

.text {
  margin-right: 2.5rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #8e8ea1;

  span {
    color: $brand-green;
  }
}
</style>
