<template>
  <div :class="$style.HomeExchangeCalculator">
    <ExchangeCalculatorInput
      direction="from"
      :directions="fromDirections"
      :currency="fromCurrency"
      :title="fromTitle"
      :value="fromValue"
      :round="round.from"
      @input="handleInputFrom"
      @change="handleChangeFrom"
    />
    <HomeExchangeSwap />
    <ExchangeCalculatorInput
      direction="to"
      :directions="toDirections"
      :currency="toCurrency"
      :title="toTitle"
      :value="toValue"
      :round="round.to"
      @input="handleInputTo"
      @change="handleChangeTo"
    >
      <template #info>
        <div v-if="congestion" :class="$style.network">
          <UiIndicatorVIndicator :load="congestion.load" />
        </div>
      </template>
    </ExchangeCalculatorInput>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCalculatorStore } from '~/stores/useCalculatorStore';
import {
  getDirectionItem,
  getDirectionsList,
} from '~/utils/calculator/directions';
import ExchangeCalculatorInput from '~/components/pages/home/exchange/ExchangeCalculatorInput.vue';
import HomeExchangeSwap from '~/components/pages/home/exchange/HomeExchangeSwap.vue';
import useDirectionsCalculator from '~/composables/calculator/useDirectionsCalculator';
import useValues from '~/composables/calculator/useValues';
import type { Direction } from '~/types/calculator';
import NotificationModal from '~/components/common/NotificationModal.vue';

interface Props {
  directions: Direction[];
}

const props = defineProps<Props>();
const calculatorStore = useCalculatorStore();
const { initCalculator } = calculatorStore;
const {
  fromCurrencyId,
  fromCurrencies,
  toCurrencies,
  toCurrencyId,
  congestion,
} = storeToRefs(calculatorStore);

if (!fromCurrencies.value || !toCurrencies.value) await initCalculator();

const fromDirections = computed(() => {
  if (!fromCurrencies.value) return [];
  return getDirectionsList(props.directions, fromCurrencies.value);
});
const toDirections = computed(() => {
  if (!toCurrencies.value) return [];
  return getDirectionsList(props.directions, toCurrencies.value);
});

const fromCurrency = computed(() => {
  if (!fromCurrencies.value) return null;
  return getDirectionItem(props.directions, fromCurrencyId.value);
});

const toCurrency = computed(() => {
  if (!toCurrencies.value) return null;
  return getDirectionItem(props.directions, toCurrencyId.value);
});

const { onFromCurrencyChange, onToCurrencyChange } = useDirectionsCalculator();

useEventBus('calculator-direction:from').on(onFromCurrencyChange);
useEventBus('calculator-direction:to').on(onToCurrencyChange);

const {
  fromValue,
  round,
  toValue,
  handleInputFrom,
  handleInputTo,
  limits,
  handleChangeFrom,
  handleChangeTo,
  notifications,
} = useValues();

const { t } = useI18n({
  useScope: 'local',
});
const fromTitle = computed(() => {
  if (!limits.value) return t('Отдаёте');
  return `${t('Отдаёте')} (${t('мин. сумма')} ${roundNumber(
    limits.value.from.min,
    round.value.from
  )} ${fromCurrency.value?.currency?.[0]}.)`;
});

const toTitle = computed(() => t('Получаете'));

const { $modal } = useNuxtApp();
watch(
  notifications,
  (value) => {
    if (value.from || value.to) {
      const notification = [];
      if (value.from) {
        notification.push({
          message: value.from,
          name: fromCurrency.value?.name,
        });
      }
      if (value.to) {
        notification.push({
          message: value.to,
          name: toCurrency.value?.name,
        });
      }
      setTimeout(() => {
        $modal.open(markRaw(NotificationModal), { notification });
      }, 0);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
// onMounted(() => {
//   $modal.open(markRaw(NotificationModal));
// });
</script>

<i18n lang="json">
{
  "ru": {
    "Отдаёте": "Отдаёте",
    "Получаете": "Получаете",
    "мин. сумма": "мин. сумма"
  },
  "en": {
    "Отдаёте": "Send",
    "Получаете": "You receive",
    "мин. сумма": "min. amount"
  }
}
</i18n>

<style lang="scss" module>
.HomeExchangeCalculator {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 2rem;

  @include respond-to(sm) {
    flex-direction: column;
    gap: 20px;
  }
}

.network {
  position: absolute;
  top: calc(100% + 26px);

  @include respond-to(sm) {
    position: relative;
    margin-top: 10px;
  }
}
</style>
