<template>
    <div :class="$style.CourseTableHead">
        <div :class="$style.item">
            <span>{{ $t('currency') }}</span>
        </div>
        <div :class="[$style.item, $style._interactive]">
            <span>{{ $t('reserves') }}</span>
            <!--            <nuxt-icon name="arrow-down" :class="$style.icon" />-->
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" module>
.CourseTableHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 8.2rem;
    padding: 0 5.8rem;

    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 3.2rem;

        span {
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 1.8rem;
        }

        &:nth-child(1) {
            width: 50%;
        }

        .icon {
            margin-left: 1.1rem;
            font-size: 1rem;
            color: $base-1000;
        }

        &._interactive {
            cursor: pointer;
        }
    }
}


</style>
